<template>
	<div class="auth">
		<header>
			<div class="title">菜单配置</div>
			<el-button type="primary" @click="addNewModule">添加菜单</el-button>
		</header>

		<div class="tree">
			<el-tree
				v-loading="loading"
				element-loading-text="加载中"
				:data="allMenu"
				node-key="menuId"
				ref="tree"
				highlight-current
				draggable
				:props="defaultProps"
				:expand-on-click-node="false"
				:default-checked-keys="defaultKeys"
				:allow-drop="allowDrop"
				@node-drop="handleDrop"
			>
				<template #default="{ node, data }">
					<span
						class="custom-tree-node"
						:style="{ width: 400 - node.level * 18 + 'px' }"
					>
						<span>{{ node.label + data.path }}</span>
						<span>
							<el-button type="text" size="mini" @click="() => append(data)">
								添加子项
							</el-button>
							<el-button type="text" size="mini" @click="() => edit(data)">
								编辑
							</el-button>
							<el-button type="text" size="mini">
								<el-popconfirm
									title="确定删除该项吗？"
									@confirm="remove(node, data)"
								>
									<template #reference>
										<span style="color: #ff4343">删除</span>
									</template>
								</el-popconfirm>
							</el-button>
						</span>
					</span>
				</template>
			</el-tree>
		</div>
		<el-dialog
			:title="activeMenu.roleId ? '编辑' : '新增'"
			width="400px"
			top="10vh"
			v-model="dialogVisible"
			@close="dialogVisible = false"
		>
			<el-form
				:model="form"
				:rules="rules"
				ref="form"
				label-width="100px"
				class="form"
			>
				<el-form-item label="名称:" prop="menuName">
					<el-input v-model="form.menuName" maxlength="100"></el-input>
				</el-form-item>
				<el-form-item label="路由:" prop="path">
					<el-input v-model="form.path" maxlength="100"></el-input>
				</el-form-item>
				<el-form-item label="图标:" prop="icon">
					<el-input v-model="form.icon" maxlength="100"></el-input>
				</el-form-item>
				<el-form-item label="权限字符串:" prop="perms">
					<el-input v-model="form.perms" maxlength="100"></el-input>
				</el-form-item>
				<el-form-item label="类型:" prop="menuType">
					<el-select v-model="form.menuType">
						<el-option label="目录" value="M"></el-option>
						<el-option label="菜单" value="C"></el-option>
						<el-option label="按钮" value="F"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注:">
					<el-input
						type="textarea"
						v-model="form.remark"
						maxlength="100"
					></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="handleSave(form)">保 存</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
import store from "@/store";
export default {
	props: ["info"],
	components: {},
	data() {
		return {
			dialogVisible: false,
			defaultProps: {
				children: "children",
				label: "menuName",
			},
			form: {
				roleSort: "1",
				dataScope: "1",
				roleSort: "1",
				remark: "",
			},
			defaultKeys: [],
			checkedCode: {},
			rules: {
				menuName: [
					{ required: true, message: "请输入菜单名称", trigger: "blur" },
				],
				menuType: [
					{ required: true, message: "请选择菜单类型", trigger: "change" },
				],
			},
			// tree相关
			loading: false,
			allMenuChecked: false,
			allButtonChecked: false,
			treeCheckedId: [],
			activeMenuButtons: [],
			activeMenu: {},
			allMenu: [],
		};
	},
	computed: {
		dictMap() {
			return this.$store.state.dictMap;
		},
	},
	watch: {},
	mounted() {
		this.getMenu();
	},
	methods: {
		handleDrop(draggingNode, dropNode, dropType, ev) {
			console.log(dropNode);
			let siblings =
				dropNode.level === 1
					? dropNode.parent.data
					: dropNode.parent.data.children;
			let proArr = [];
			siblings.forEach((e, i) => {
				e.orderNum = i + 1;
				proArr.push(axios.put("/system/menu/", e));
			});
			Promise.all(proArr).then(() => {
				this.$message.success("排序成功");
			});
		},
		allowDrop(draggingNode, dropNode, type) {
			return type !== "inner";
		},
		addNewModule() {
			let orderNum = 0;
			if (this.allMenu && this.allMenu.length) {
				orderNum =
					this.allMenu.sort((a, b) => b.orderNum - a.orderNum)[0].orderNum | 0;
			}
			this.form = {
				parentId: 1,
				orderNum: orderNum + 1,
			};
			this.activeMenu = {
				children: this.allMenu,
			};
			this.dialogVisible = true;
		},
		append(data) {
			console.log(data);
			this.activeMenu = data;
			this.dialogVisible = true;
			let orderNum = 0;
			if (data.children && data.children.length) {
				orderNum =
					data.children.sort((a, b) => b.orderNum - a.orderNum)[0].orderNum | 0;
			}
			console.log(orderNum);
			this.form = {
				parentId: data.menuId,
				orderNum: orderNum + 1,
			};
		},
		edit(data) {
			console.log(data);
			this.form = data;
			this.dialogVisible = true;
		},
		remove(node, data) {
			console.log(data);
			axios.delete(`/system/menu/${data.menuId}`).then((res) => {
				let parent = node.parent;
				let children = parent.data.children || parent.data;
				let index = children.indexOf(data);
				console.log(index);
				children.splice(index, 1);
				this.$message.success("删除成功");
			});
		},
		handleSave() {
			this.$refs["form"].validate((valid) => {
				if (!valid) {
					return;
				}
				axios[this.form.menuId ? "put" : "post"](
					"/system/menu/",
					this.form
				).then((res) => {
					console.log(res);
					// this.$store.dispatch("getMenu");
					this.getMenu();
					this.$message.success("操作成功");
					this.dialogVisible = false;
					if (!this.activeMenu.children) {
						this.$set(this.activeMenu, "children", []);
					}
					this.activeMenu.children.push(
						Object.assign({}, typeof res === "object" ? res.data : this.form)
					);
					this.$nextTick(() => {
						this.form = {};
					});
				});
			});
		},
		getMenu() {
			axios({
				method: "get",
				url: "/system/menu/list",
			}).then((res) => {
				console.log("getMenu", res);

				let map = {
					1: {
						children: [],
					},
				};

				res.forEach((e) => {
					map[e.menuId] = e;
				});
				res.forEach((e) => {
					map[e.parentId] && map[e.parentId].children.push(e);
				});
				this.allMenu = map[1].children;
			});
		},
		list2Tree(list, parentId = 1) {
			// 数组转树形
			let menuObj = {};
			if (list && list.length > 0) {
				list.forEach((item, index) => {
					item.children = [];
					menuObj[item.menuId] = item;
				});
				return list.filter((item) => {
					if (item.parentId !== parentId) {
						console.log(item, menuObj);
						menuObj[item.parentId].children.push(item);
						return false;
					}
					return true;
				});
			}
			return menuObj;
		},
	},
};
</script>

<style scoped lang="stylus">
.custom-tree-node {
	display: inline-block;
	width: 400px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.auth {
	height: 100%;
	position: relative;
	line-height: 30px;

	header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 40px;

		.title {
			font-size: 20px;
			font-weight: bold;
		}
	}

	.tree {
		padding: 24px;
		background: #fff;
	}
}

.tree-box {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;

	.tree {
		width: 30%;
		overflow: scroll;
	}

	.table {
		width: 70%;
	}
}
</style>
